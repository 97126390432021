import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { getConversations } from "../../services/api";
import { ConversationList } from "../conversationList";
import { ContextProps } from "../../pages/homePage";

export interface conversationProps {
  name: string | undefined;
  photo: string | undefined;
  isGroup: boolean;
  userId: number | undefined;
  isAdmin: boolean;
  groupId?: number | undefined;
}

export interface optionsProps {
  action: string;
  onClick: () => void;
}


export default function BlockConversation() {
  const [conversationList, setConversationList] = useState<conversationProps[]>([]);
  const { setSelectedGroupId } = useContext(ContextProps);

  const getConversationList = async () => {
    const userId = localStorage.getItem('userId');
    const data = await getConversations({ id: userId, isBlock: true });
    setConversationList(data);
  };

  useEffect(() => {
    getConversationList();
  }, []);

  useEffect(() => {
    setSelectedGroupId(conversationList.length > 0 ? (conversationList[0].groupId || null): null);
  }, [conversationList]);

  return (
    <Box p="20px">
      {conversationList && conversationList.length > 0 && conversationList.map((val, index) => {
        return (
          <ConversationList
            key={`conversation${index}`}
            setDefaultList={setConversationList}
            list={val}
            isBlocked={true}
          />
        )
      })}
      {conversationList && conversationList.length === 0 && <Typography textAlign={"center"} color={"red"}>No User is Blocked</Typography> }
    </Box>
  )
}