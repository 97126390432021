import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { getContacts, getConversations } from "../../services/api";
import SearchBar from "../searchBar";
import useDebounce from "../../hooks/UseDebounce";
import { ConversationList } from "../conversationList";
import { ContextProps } from "../../pages/homePage";
import * as io from "socket.io-client";
import { API_LINK } from "../../contants/app";
const socket = io.connect(API_LINK);

export interface conversationProps {
  name: string | undefined;
  photo: string | undefined;
  isGroup: boolean;
  userId: number | undefined;
  isAdmin: boolean;
  groupId?: number | undefined;
  lastSeen?: string | null;
  unreadMessage?: number | undefined;
}

export interface optionsProps {
  action: string;
  onClick: () => void;
}

export interface conversationProp {
  setEditGroupId: (val: number) => void;
  setSelectedTab: (val: string) => void;
  defaultList: conversationProps[],
  setDefaultList: (val: conversationProps[]) => void;
}

export default function Conversation({ setEditGroupId, setSelectedTab, defaultList, setDefaultList }: conversationProp) {
  const [conversationList, setConversationList] = useState<conversationProps[]>([]);
  const [searchContacts, setSearchContacts] = useState<conversationProps[]>([]);
  const [contacts, setContacts] = useState<conversationProps[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isRoomCreated, setIsRoomCreated] = useState<boolean>(false);

  const { setSelectedGroupId, selectedGroupId } = useContext(ContextProps);

  // Debounce the search term
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getConversationList = async () => {
    const userId = localStorage.getItem('userId');
    const data = await getConversations({ id: userId });
    setSelectedGroupId(data[0].groupId);
    setDefaultList(data);
  };

  const getContactList = async () => {
    const userId = localStorage.getItem('userId');
    const data = await getContacts({ id: userId });
    setContacts(data);
  };

  const fetchSearchResults = (searchStr: string, contacts: any, defaultList: any) => {
    if (searchStr !== '') {
      const conversationSearch = defaultList
        .filter((val: any) => {
          return (val.name.toLowerCase())
            .startsWith(searchStr.toLowerCase())
        });

      const data = contacts.filter((val: any) => !defaultList.some((item: any) => {
        return item.userId !== undefined && item.userId === val.userId;
      }));

      const contactSearch = data.filter((val: any) => {
        return (val.name.toLowerCase())
          .startsWith(searchStr.toLowerCase())
      });

      setConversationList(conversationSearch);
      setSearchContacts(contactSearch);
    } else {
      setConversationList(defaultList);
      if (defaultList.length > 0 && !isRoomCreated)
        setSelectedGroupId(defaultList[0].groupId)
      else if (isRoomCreated)
        setIsRoomCreated(false);
    }
  }

  useEffect(() => {
    getConversationList();
    getContactList();
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchSearchResults(debouncedSearchTerm, contacts, defaultList);
    } else {
      setConversationList(defaultList);
      if (defaultList.length > 0 && defaultList[0].groupId && !isRoomCreated) {
        // setSelectedGroupId(defaultList[0].groupId) // commented for after polling its not working

      }
      else if (isRoomCreated) {
        setIsRoomCreated(true);
      }
      setSearchContacts([]);
    }
  }, [debouncedSearchTerm, defaultList, contacts]);

  useEffect(() => {
    socket.on("room_created", (data: any) => {
      if (data) {
        setIsRoomCreated(true);
        setSelectedGroupId(data);
        getConversationList();
        getContactList();
        setSearchTerm('');
      }
    });

    const sendPollingRequest = () => {
      const userId = localStorage.getItem('userId');
      socket.emit('pollingRequest', { userId, groupId: selectedGroupId });
    };

    // Set up the interval to send the request every 10 seconds
    const interval = setInterval(() => {
      sendPollingRequest();
    }, 10000);

    socket.on('pollingResponse', (data) => {
      setDefaultList(data?.message);
    });

    // Clean up the interval and socket connection on component unmount
    return () => {
      clearInterval(interval);
      // socket.disconnect();
    };

  }, [socket]);

  return (
    <Box p="20px">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {conversationList && conversationList.length > 0 && conversationList.map((val, index) => {
        return (
          <ConversationList
            key={`conversation${index}`}
            setDefaultList={setDefaultList}
            list={val}
            setEditGroupId={setEditGroupId}
            setSelectedTab={setSelectedTab}
            socket={socket}
          />
        )
      })}
      {debouncedSearchTerm !== "" && searchContacts && searchContacts.length > 0 && searchContacts.map((val, index) => {
        return (
          <>
            <Typography style={{ padding: '10px', fontWeight: "500", backgroundColor: 'lightgrey' }}>Other Contact List</Typography>
            <ConversationList
              key={`conversation${index}`}
              setDefaultList={setDefaultList}
              list={val}
              socket={socket}
            />
          </>
        )
      })}
    </Box>
  )

}