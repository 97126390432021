import { SyntheticEvent, useEffect } from "react";

import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import Conversation, { conversationProps } from "../conversation";
import BlockConversation from "../blockConversation";
import AddEditGroup from "../addEditGroup";

type SideBarProps = {
  showDrawer: boolean;
  setShowDrawer: any;
  selectedTab: string;
  setSelectedTab: (val: string) => void;
  setEditGroupId: (val: number | null) => void;
  editGroupId: number | null;
  defaultList: conversationProps[];
  setDefaultList: (val: conversationProps[]) => void;
};

const SideBar = ({
  showDrawer,
  setShowDrawer,
  selectedTab,
  setSelectedTab,
  setEditGroupId,
  editGroupId,
  defaultList,
  setDefaultList
}: SideBarProps) => {

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return ( 
    <Drawer
      open={showDrawer}
      variant="persistent"
      transitionDuration={400}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" padding={2}>Contacts</Typography>
        <IconButton onClick={() => setShowDrawer((val: boolean) => !val)}>
          <SyncAltIcon />
        </IconButton>
      </Stack>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTabs-flexContainer": {
              borderBottom: "2px solid lightGrey",
              justifyContent: 'space-around',

            },
          }}
        >
          <Tab
            icon={<InterpreterModeIcon />}
            value="1"
            sx={{
              borderRadius: "8px 8px 0px 0px",
              minWidth: '80px !important',
              "&.Mui-selected": {
                color: "inherit",
                borderLeft: "2px solid lightGrey",
                borderBottom: "none",
                borderRight: "2px solid lightGrey",
                borderTop: "2px solid lightGrey",
              },
            }}
          />
          <Tab
            icon={<GroupsIcon />}
            value="2"
            sx={{
              borderRadius: "8px 8px 0px 0px",
              minWidth: '80px !important',
              "&.Mui-selected": {
                color: "inherit",
                borderBottom: "none",
                borderLeft: "2px solid lightGrey",
                borderRight: "2px solid lightGrey",
                borderTop: "2px solid lightGrey",
              },
            }}
          />
          <Tab
            icon={<CloseOutlinedIcon />}
            value="4"
            sx={{
              borderRadius: "8px 8px 0px 0px",
              minWidth: '80px !important',
              "&.Mui-selected": {
                color: "inherit",
                borderBottom: "none",
                borderRight: "2px solid lightGrey",
                borderLeft: "2px solid lightGrey",
                borderTop: "2px solid lightGrey",
              },
            }}
          />
        </Tabs>
        {selectedTab === "1" && (
          <Conversation
            setEditGroupId={setEditGroupId}
            setSelectedTab={setSelectedTab}
            defaultList={defaultList}
            setDefaultList={setDefaultList}
          />
        )}
        {selectedTab === "2" && (
          <AddEditGroup />
        )}
        {selectedTab === "4" && <BlockConversation />}
      </Box>
    </Drawer>
  );
};

export default SideBar;
