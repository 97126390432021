import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface props {
  open: boolean;
  onClose: () => void
  message: string;
}

function CustomSnackbar({ open, onClose, message }: props) {
  const handleClose = () => {
    onClose();
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <MuiAlert
        onClose={handleClose}
        severity="error"
        
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default CustomSnackbar;
