import { AvatarGroup, Box, Paper, Stack, styled, Typography } from "@mui/material";
import { TextInput } from "../sendMessage";
import { MessageLeft, MessageRight } from "../sendMessage/messages";
import { useContext, useEffect, useRef, useState } from "react";
import { ContextProps } from "../../pages/homePage";
import { getGroupDetails, getGroupMessages } from "../../services/api";
import { groupMessagesByDay, moveRandomElementToFront } from "../../utils/utility";
import { conversationProps } from "../conversation";
import { HeaderAppBar } from "../layout";
import ConditionalAvatar from "../avatarComponent";
import { AnyCnameRecord } from "dns";

const MainBox = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

const MainPaper = styled(Paper)({
  width: "100%",
  height: "95vh",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
  boxShadow: "none",
})


enum MessageType {
  text = 'text',
  video = 'video',
  image = 'image',
  doc = 'doc'
}

enum UserStatus {
  online = 'online',
  typing = 'typing',
  online_week = 'online_week',
  Offline = 'Offline',
}

export interface MessgeListProps {
  group_id: number;
  message: string;
  message_by: number;
  message_type: MessageType;
  user_name: string;
  profile_picture: string;
  created_at: string;
  onlineStatus: UserStatus;
  message_id: number;
  image: string;
}

export interface GroupedMessages {
  [date: string]: MessgeListProps[];
};

export default function MessageBox({
  isChatDisabled,
  socket,
  setDefaultList,
  defaultList,
  setEditGroupId,
  setSelectedTab,
}: {
  isChatDisabled: boolean,
  socket: any,
  setDefaultList: any
  defaultList: conversationProps[];
  setEditGroupId: any;
  setSelectedTab: any;
}) {
  const { selectedGroupId, setSelectedGroupId } = useContext(ContextProps);
  const [messageList, setMessageList] = useState<MessgeListProps[]>([]);
  const [messageGroupBy, setMessageGroupBy] = useState<GroupedMessages>({});
  const [isSocketRecived, setIsSocketRecived] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [image, setImage] = useState<any>(null);
  const [memebers, setMembers] = useState<conversationProps[]>([]);
  const [isNewMsg, setIsNewMsg] = useState<boolean>(false);
  const [newMessageData, setNewMessageData] = useState<any>({});
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(1);
  const userIdStr = localStorage.getItem('userId');
  const containerRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  let loginUserId: number = userIdStr ? parseInt(userIdStr) : 0;

  const getGroupDetail = async (groupId: number) => {
    const data = await getGroupDetails(groupId);
    setGroupName(data?.group_name || "");
    setImage(data?.photo || null);

    const members = (data?.group_users || []).map((val: any) => {
      return {
        name: `${val?.user?.fname || ""} ${val?.user?.lname || ""}`,
        photo: val?.user?.profile_photo,
        isGroup: false,
        userId: val?.user_id,
        isAdmin: false,
      }
    });
    setMembers(members);
  }

  const getMessageList = async (groupId: number) => {
    try {
      const messages = await getGroupMessages(groupId);
      setMessageList(messages);
      if (containerRef?.current) {
        setTimeout(() => {
          containerRef?.current?.scrollIntoView({ behavior: 'instant', block: 'end' });
        }, 200)
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  const loadMoreMessages = async (scrollHeight: number) => {
    if (!hasMore) return;
    try {
      const response = await getGroupMessages(selectedGroupId, pageNumber + 1)
      setPageNumber(pageNumber + 1);
      setTimeout(() => {
        if (containerRef?.current?.clientHeight) {
          chatContainerRef?.current?.scrollTo({ top: containerRef?.current?.clientHeight - scrollHeight - 100, behavior: "instant" });
        }
      }, 200);
      if (response.length > 0) {
        setMessageList((prevMessages) => [...response, ...prevMessages]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching more messages:', error);
    }
  };

  const handleScroll = () => {
    const container = chatContainerRef.current;
    if (container && containerRef?.current) {
      if (container.scrollTop === 0) {
        loadMoreMessages(containerRef?.current.clientHeight);
      }
    }
  }

  useEffect(() => {
    if (selectedGroupId) {
      getMessageList(selectedGroupId);
      getGroupDetail(selectedGroupId);
    } else {
      setMessageList([]);
      setMembers([]);
      setGroupName('');
      setImage(null)
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (messageList.length > 0) {
      setMessageGroupBy(groupMessagesByDay(messageList));
    } else {
      setMessageGroupBy({});
    }
  }, [messageList])

  useEffect(() => {
    if (isSocketRecived) {
      if (selectedGroupId === newMessageData?.group_id) {
        setMessageList((list: MessgeListProps[]) => {
          const isExist = list.find(val => val.message_id === newMessageData.message_id);
          if (isExist) {
            return list;
          } else {
            return [...list, newMessageData];
          }
        });
      }
      // New message recived, so move group in first row
      if (selectedGroupId && isNewMsg && defaultList[0].groupId !== selectedGroupId) {
        setDefaultList((val: conversationProps[]) => {
          const randomIndex = val.findIndex(val => val.groupId === selectedGroupId)
          if (randomIndex !== -1) {
            return JSON.parse(JSON.stringify(moveRandomElementToFront(val, randomIndex)));
          } else {
            return val;
          }
        });
        const userId = localStorage.getItem('userId');
        socket.emit("join_room", { room: selectedGroupId, userId: userId });
        setIsNewMsg(false);
      } else if (!isNewMsg) { // New message is not recived its means group is deleted 
        setDefaultList((val: any) => {
          return val.filter((val: any) => {
            return val.groupId !== selectedGroupId;
          })
        })
      }
      setIsSocketRecived(false);
    }
  }, [isSocketRecived, defaultList])

  useEffect(() => {
    socket.on("receive_message", (data: any) => {
      if (data?.message_id) {
        setNewMessageData(data);
        setIsNewMsg(true);
        if (containerRef?.current) {
          setTimeout(() => {
            containerRef?.current?.scrollIntoView({ behavior: 'instant', block: 'end' });
          }, 200)
        }
      }
      setIsSocketRecived(true);
    });
  }, [socket]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (
    <MainBox>
      <MainPaper>
        {groupName && groupName !== "" && <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginRight={2}
          height={"10vh"}
          width={"100%"}
          paddingLeft={2}
        >
          {image && <Box
            mt={2}
            sx={{
              position: 'relative',
              display: 'inline-block',
              cursor: 'pointer',
            }}
          >
            <img
              src={image}
              height={"50px"}
              width={"50px"}
              alt="Preview"
              style={{ cursor: 'pointer' }}
            />

          </Box>}
          <Typography fontWeight={"bold"} paddingLeft={2} fontSize={24}>{groupName}</Typography>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <AvatarGroup
              total={memebers.length}
              onClick={(e: any) => {
                if (e?.target && e?.target?.className) {
                  const className = e?.target?.className || "";
                  if (className.includes("MuiAvatarGroup-avatar")) {
                    setEditGroupId(selectedGroupId);
                    setSelectedGroupId(null);
                    setSelectedTab("2")
                  }
                }
              }}
            >
              {memebers.slice(0, 4).map((memeber, index) => (
                <ConditionalAvatar
                  src={memeber.photo}
                  name={memeber.name || ""}
                  key={`members${index}`}
                />
              ))}
            </AvatarGroup>
          </Stack>
        </Stack>}
        <div
          ref={chatContainerRef}
          className="chat-container"
          style={{
            width: "calc( 100% - 20px )",
            margin: 10,
            height: groupName && groupName !== "" ? "60vh" : "70vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            boxShadow: "none",
          }}
        >
          <div ref={containerRef}>
            {messageGroupBy && Object.keys(messageGroupBy).map((date: any) => (
              <Box key={date} sx={{ mb: 2 }}>
                <Typography textAlign={"center"} variant="h6" sx={{ mb: 1 }} color={"lightgray"}>
                  {date}
                </Typography>
                {messageGroupBy[date] && Array.isArray(messageGroupBy[date]) && messageGroupBy[date].length > 0 && messageGroupBy[date].map((val: MessgeListProps, index: number) => (
                  <div key={`message_${index}`}>
                    {val.message_by == loginUserId ? (
                      <MessageRight
                        message={val.message}
                        timestamp={val.created_at}
                        photoURL={val.profile_picture}
                        displayName={val.user_name}
                        avatarDisp={val.profile_picture ? false : true}
                        image={val.image}
                      />
                    ) : (
                      <MessageLeft
                        message={val.message}
                        timestamp={val.created_at}
                        photoURL={val.profile_picture}
                        displayName={val.user_name}
                        avatarDisp={val.profile_picture ? false : true}
                        setDefaultList={setDefaultList}
                        image={val.image}
                      />)}
                  </div>
                ))}
              </Box>))}
          </div>
        </div>
        <TextInput
          isChatDisabled={isChatDisabled}
          selectedGroupId={selectedGroupId}
          loginUserId={loginUserId}
          isMessageAvailable={Object.keys(messageGroupBy).length === 0}
          socket={socket}
        />
      </MainPaper>
    </MainBox>
  );
}
