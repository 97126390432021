import { Avatar, AvatarGroup, Backdrop, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, IconButton, Modal, Snackbar, Stack, TextField, Typography } from "@mui/material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { createGroup, getAllContacts, getGroupDetails, updateGroup } from "../../services/api";
import { ChangeEvent, useEffect, useState } from "react";
import { conversationProps } from "../addEditGroup";
import ConditionalAvatar from "../avatarComponent";
import { BottomAppBar, HeaderAppBar } from "../layout";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { fileValidation } from "../../utils/utility";
import CustomSnackbar from "../customSnackbar";

interface AddEditGroupInfoProps {
  showDrawer: boolean;
  setSelectedTab: any
  editGroupId: number | null;
}
export default function AddEditGroupInfo({ showDrawer, setSelectedTab, editGroupId }: AddEditGroupInfoProps) {
  const [contacts, setContacts] = useState<conversationProps[]>([]);
  const [memebers, setMembers] = useState<conversationProps[]>([]);
  const [groupName, setGroupName] = useState<string>("");
  const [image, setImage] = useState<any>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [open, setOpen] = useState<boolean>(true);
  const [errorGroupName, setErrorGroupName] = useState<string | null>(null);
  const [errorMembers, setErrorMembers] = useState<string | null>(null);
  const [errorFile, setErrorFile] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files.length > 0) {
      const file = event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
        setImageFile(file);
      }
    }
  };

  const getContactList = async () => {
    const userId = localStorage.getItem('userId');
    const data = await getAllContacts({ id: userId });
    if (!data?.message)
      setContacts(data);
  };

  function toggleArrayValue<T>(array: conversationProps[], value: conversationProps): conversationProps[] {
    const index = array.findIndex(item => item.userId === value.userId);
    if (index === -1) {
      return [...array, value];
    } else {
      return array.filter(item => item.userId !== value.userId);
    }
  }


  const validateGroup = (groupName: string, members: conversationProps[], imageFile: any) => {
    let flag = false;
    if (!groupName) {
      flag = true;
      setErrorGroupName("Enter Group name");
    }
    if (members.length === 0) {
      flag = true;
      setErrorGroupName("Please add the members");
    }
    if (imageFile) {
      const fileError = fileValidation(imageFile);
      if (fileError) {
        setErrorFile(fileError);
        setImageFile(null);
        setImage(null);
        flag = true;
      }
    }
    return flag;
  }

  const groupCreatEditPayload = (groupName: string, memebers: conversationProps[], image: any, editGroupId: number | null = null) => {
    const userId = localStorage.getItem('userId');
    const formData = new FormData();
    formData.append('groupName', groupName);
    formData.append('userId', userId + "");
    if (!image) {
      formData.append('file', image);
    };
    if (editGroupId) {
      formData.append('groupId', editGroupId + "");
    }
    memebers.forEach(member => {
      if (member.userId)
        formData.append('members', member.userId + "")
    });
    return formData;
  }

  const createGroupMember = async (groupName: string, memebers: conversationProps[], image: any, editGroupId: number | null) => {
    if (!validateGroup(groupName, memebers, image)) {
      setOpen(true);
      const formData = groupCreatEditPayload(groupName, memebers, image, editGroupId);
      try {
        let response;
        if (editGroupId) {
          response = await updateGroup(formData);
        } else {
          response = await createGroup(formData);
        }
        setOpen(false);
        if (response !== undefined) {
          setSelectedTab("1");
        }
      } catch (error) {
        setOpen(false);
        console.error('Error uploading image:', error);
      }
    }
  }

  const getGroupDetail = async (groupId: number) => {
    const data = await getGroupDetails(groupId);
    setGroupName(data?.group_name || "");
    setImage(data?.photo || null);

    const members = (data?.group_users || []).map((val: any) => {
      return {
        name: val?.user?.name,
        photo: val?.user?.profile_photo,
        isGroup: false,
        userId: val?.user_id,
        isAdmin: false,
      }
    });
    setMembers(members);
  }

  useEffect(() => {
    if (editGroupId) {
      getGroupDetail(editGroupId);
    }
  }, [editGroupId]);

  useEffect(() => {
    getContactList();
  }, []);

  return (
    <Box>
      <HeaderAppBar
        position="fixed"
        open={showDrawer}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginRight={2}
        >
          <TextField
            style={{ width: '50%' }}
            id="outlined-multiline-flexible"
            label="Enter Group Name"
            multiline
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
            maxRows={2}
            inputProps={{ maxLength: 100 }}
          />
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="icon-button-file"
                type="file"
                onChange={handleImageChange}
              />
              {image === null ? (<label
                htmlFor="icon-button-file"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: "row-reverse",
                  cursor: 'pointer',
                }}
              >
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <CameraAltOutlinedIcon
                    color="info"
                    fontSize="large"
                  // style={{ marginRight: 8 }}
                  />
                </IconButton>
              </label>) : (
                <Box
                  mt={2}
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={image}
                    height={"50px"}
                    width={"50px"}
                    alt="Preview"
                    style={{ cursor: 'pointer' }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: -4,
                      right: -4,
                      width: '16px',
                      height: '16px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                    }}
                    onClick={() => {
                      setImage(null);
                      setImageFile(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </>
            <AvatarGroup total={memebers.length}>
              {memebers.slice(0, 4).map((memeber, index) => (
                <ConditionalAvatar
                  src={memeber.photo}
                  name={memeber.name || ""}
                  key={`members${index}`}
                />
              ))}
            </AvatarGroup>
          </Stack>
        </Stack>
      </HeaderAppBar >
      <Stack
        marginTop={10}
        marginBottom={3}
      >
        <Typography fontWeight={"bold"} paddingLeft={1}>
          Select People to be in Group by clicking
        </Typography>
        <Stack
          marginBottom={4}
          display={"grid"}
          gridTemplateColumns={"repeat(auto-fill,minmax(150px, 150px))"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-around"}
        >
          {contacts && contacts.length > 0 && contacts.map(val => (
            <Card
              key={`cards${val.userId}`}
              sx={{
                margin: 2,
                height: "180px",
              }}
            >
              <CardActionArea
                sx={{
                  justifyContent: "center",
                  display: 'flex',
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 1,
                  backgroundColor: memebers.findIndex(item => item.userId === val.userId) === -1 ? "white" : "blue",
                }}
                onClick={() => {
                  setMembers((memeber: conversationProps[]) => {
                    if (val.userId) {
                      return toggleArrayValue(memeber, val)
                    }
                    return memeber;
                  })
                }}
              >
                <ConditionalAvatar
                  src={val.photo}
                  name={val.name || null}
                  avatarStyle={{ width: "100px", height: "100px" }}
                />
                <CardContent
                  sx={{
                    textAlign: "center"
                  }}
                >
                  <Typography gutterBottom variant="body1" component="div">
                    {val.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      </Stack>
      {contacts && contacts.length === 0 && <Typography paddingLeft={1}>No Contacts founds</Typography>}
      <BottomAppBar
        position="fixed"
        open={showDrawer}
      >
        <Stack spacing={2} direction="row-reverse">
          <Button
            variant="contained"
            onClick={() => {
              createGroupMember(groupName, memebers, imageFile, editGroupId);
            }}
          >
            {editGroupId ? "Update" : "Save"}
          </Button>
        </Stack>
      </BottomAppBar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!open}
        onClick={() => setOpen(!open)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorGroupName}
      {errorMembers}
      {errorFile}
      <CustomSnackbar
        open={errorGroupName !== null}
        onClose={() => setErrorGroupName(null)}
        message={errorGroupName || ""}
      />
      <CustomSnackbar
        open={errorMembers !== null}
        onClose={() => setErrorMembers(null)}
        message={errorMembers || ""}
      />
      <CustomSnackbar
        open={errorFile !== null}
        onClose={() => setErrorFile(null)}
        message={errorFile || ""}
      />
    </Box >
  )
}