import { conversationProps } from "../components/conversation";
import { GroupedMessages, MessgeListProps } from "../components/rightContent/messageBox";
import { FILE_TYPES, MAX_FILE_SIZE } from "../contants/app";
import { DateTime } from 'luxon';

export const fileValidation = (file: any) => {
  if (file.type && !FILE_TYPES.includes(file.type)) {
    return "Only JPEG and PNG files are allowed.";
  }
  if (file.size && file.size > MAX_FILE_SIZE) {
    return 'File size should be less than 5MB.';
  }
  return;
}

export const generateRandomString = () => {
  const length = 15;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0;i < length;i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const formatDate = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  const now = DateTime.now();

  const daysDifference = now.diff(date, 'days').days;
  const weeksDifference = now.diff(date, 'weeks').weeks;
  const monthsDifference = now.diff(date, 'months').months;
  // const yearsDifference = now.diff(date, 'years').years;

  if (daysDifference < 1) {
    return date.toFormat('LLL dd hh:mm a');
  } else if (daysDifference < 7) {
    return date.toFormat('cccc, hh:mm a'); // returns the weekday name
  } else if (weeksDifference < 4) {
    // const weekDiffValue = Math.floor(weeksDifference);
    return date.toFormat('yyyy LLL dd hh:mm a');//`${weekDiffValue== 1 ? "a": weekDiffValue} week${Math.floor(weeksDifference) > 1 ? 's' : ''} ago`;
  } else if (monthsDifference < 12) {
    // const monthDiffValue = Math.floor(monthsDifference);
    return date.toFormat('yyyy LLL dd hh:mm a');//`${monthDiffValue== 1 ? "a": monthDiffValue} month${Math.floor(monthsDifference) > 1 ? 's' : ''} ago`;
  } else {
    // const yearDiffValue = Math.floor(yearsDifference);
    return date.toFormat('yyyy LLL dd hh:mm a'); //`${yearDiffValue === 1 ? "a" : yearDiffValue} year${Math.floor(yearsDifference) > 1 ? 's' : ''} ago`;
  }
}

export const groupMessagesByDay = (messages: MessgeListProps[]): GroupedMessages => {
  return messages.reduce((groups: GroupedMessages, message: MessgeListProps) => {
    const date = DateTime.fromISO(message.created_at);
    const now = DateTime.now();
    const dateKey = DateTime.fromISO(message.created_at).toFormat('yyyy LLL dd')
    const daysDifference = now.diff(date, 'days').days;
    let key = "";
    if (daysDifference < 1) {
      key = "Today"
    } else if (daysDifference < 2) {
      key = "Yesterday"
    } else {
      key = dateKey;
    }
    // const date = DateTime.fromISO(message.created_at).toFormat('yyyy LLL dd');

    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(message);
    return groups;
  }, {});
};

export const moveRandomElementToFront = (arr: conversationProps[], randomIndex: number): conversationProps[] => {
  if (arr.length === 0) return arr;

  // If the random index is already the first element, return the array as is
  if (randomIndex === 0) return arr;

  // Extract the element at the random index
  const [element] = arr.splice(randomIndex, 1);

  // Add the extracted element to the front of the array
  arr.unshift(element);
  return arr;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str.length === 0) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const containsEmoji = (text: string) => {
  return /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(text);
}

export const getDateDifference = (lastSeen: string | null) => {
  if (lastSeen === null) {
    return "red";
  } else {
    const date = DateTime.fromISO(lastSeen);
    const now = DateTime.now();
    const daysDifference = now.diff(date, 'days').days;

    if (daysDifference < 1) {
      return "#44b700"
    } else if (daysDifference < 7) {
      return "orange"
    } else {
      return "red";
    }
  }

}