import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { getAllContacts } from "../../services/api";
import SearchBar from "../searchBar";
import useDebounce from "../../hooks/UseDebounce";
import { ConversationList } from "../conversationList";

export interface conversationProps {
  name: string | undefined;
  photo: string | undefined;
  isGroup: boolean;
  userId: number | undefined;
  isAdmin: boolean;
  groupId?: number | undefined;
  isBlock?: boolean | undefined;
}

export interface optionsProps {
  action: string;
  onClick: () => void;
}


export default function AddEditGroup() {
  const [searchContacts, setSearchContacts] = useState<conversationProps[]>([]);
  const [contacts, setContacts] = useState<conversationProps[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  // Debounce the search term
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getContactList = async () => {
    const userId = localStorage.getItem('userId');
    const data = await getAllContacts({ id: userId });
    setContacts(data);
  };


  const fetchSearchResults = (searchStr: string, contacts: any) => {
    if (searchStr !== '') {
      const contactSearch = contacts.filter((val: any) => {
        return (val.name.toLowerCase())
          .startsWith(searchStr.toLowerCase())
      });

      setSearchContacts(contactSearch);
    } else {
      setContacts(contacts);
    }
  }

  useEffect(() => {
    getContactList();
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchSearchResults(debouncedSearchTerm, contacts);
    } else {
      setContacts(contacts);
      setSearchContacts(contacts);
    }
  }, [debouncedSearchTerm, contacts]);

  return (
    <Box p="20px">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {searchContacts && searchContacts.length > 0 && searchContacts.map((val, index) => {
        return (
          <ConversationList
            key={`conversation${index}`}
            setDefaultList={setContacts}
            list={val}
            isBlocked={val?.isBlock ?? false}
          />
        )
      })}
    </Box>
  );
}