// import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { API_LINK } from "../contants/app";

const API_URL = `${API_LINK}/api/v1/`;
export const baseService = {
  get,
  put,
  post,
  uploadPost,
}

const getFullUrl = (url: string) => API_URL + url;

const getHeader = async (isImageUpload: boolean = false) => {
  // let IdTOken = await AsyncStorage.getItem('@accessToken');
  if (isImageUpload) {
    return {
      Authorization: `bearer ${"sd"}`,
      'Content-Type': 'multipart/form-data',
    }
  }
  return {
    Authorization: `bearer ${"sd"}`
  }
}

const handleResponse = (response: any) => {
  if (!validResponse(response.status)) {
    return Promise.reject(response);
  }

  return response.data || response;
}

const validResponse = (code: number) => {
  return (code >= 200 && code < 400)
}

function error(error: any) {
  return error;
}
async function get(url: string) {
  const header = await getHeader();
  return axios.get(getFullUrl(url), { headers: header }).then(handleResponse).catch(error);
}

async function put(url: string, body: any) {
  const header = await getHeader();
  return axios.put(getFullUrl(url), body, { headers: header }).then(handleResponse).catch(error);
}

async function post(url: string, body: any, flag: boolean = false) {
  const apiUrl = getFullUrl(url);
  if (flag) {
    const headers = await getHeader();
    return axios.post(apiUrl, body, { headers: headers }).then(handleResponse);
  } else {
    const data = axios.post(apiUrl, body).then(handleResponse).catch(error);
    return data;
  }
}

async function uploadPost(url: string, body: any, flag: boolean = false) {
  const apiUrl = getFullUrl(url);
  const headers = await getHeader();
  const data = axios.post(apiUrl, body).then(handleResponse).catch(error);
  return data;
}