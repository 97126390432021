import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface barProps {
  searchTerm: string;
  setSearchTerm: (val:string) => void;
}

const SearchBar = ({ searchTerm, setSearchTerm }: barProps) => {

  return (
    <Box p={"10px 0"}>
      <TextField
        value={searchTerm}
        onChange={e => setSearchTerm(e?.target?.value)}
        variant="outlined"
        sx={{ width:'100%'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
