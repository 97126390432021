import { createContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from "@mui/material";
import SideBar from "../../components/sideBar";
import RightContent from "../../components/rightContent";
import { conversationProps } from "../../components/conversation";
import { getUserDetailById } from "../../services/api";

const drawerWidth = 340;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(1),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// Define the type of the context value
interface ContextPropsType {
  selectedGroupId: number | null;
  setSelectedGroupId: (newValue: number | null) => void;
  chatRoom: string | null;
  setChatRoom: (newValue: string | null) => void;
}

export const ContextProps = createContext<ContextPropsType>({} as ContextPropsType);

export default function PersistentDrawerLeft() {
  const [open, setOpen] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [defaultList, setDefaultList] = useState<conversationProps[]>([]);
  const [chatRoom, setChatRoom] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("1");
  const [editGroupId, setEditGroupId] = useState<number | null>(null);
  // const contextProps = useContext()
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const getLoginUserDetail = async () => {
    const userDetail = localStorage.getItem('userData');
    const userId = localStorage.getItem('userId');

    if (!userDetail && userId) {
      const userData = await getUserDetailById(userId);
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  }
  useEffect(() => {
    getLoginUserDetail();
  }, []);

  return (
    <ContextProps.Provider value={{ selectedGroupId, setSelectedGroupId, chatRoom, setChatRoom }}>
      <Box sx={{ display: 'flex' }}>
        <Stack>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Stack>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <SideBar
            showDrawer={open}
            setShowDrawer={setOpen}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setEditGroupId={setEditGroupId}
            editGroupId={editGroupId}
            defaultList={defaultList}
            setDefaultList={setDefaultList}
          />
        </Drawer>
        <Main open={open}>
          <RightContent
            selectedTab={selectedTab}
            showDrawer={open}
            setSelectedTab={setSelectedTab}
            setEditGroupId={setEditGroupId}
            editGroupId={editGroupId}
            selectedGroupId={selectedGroupId}
            setSelectedGroupId={setSelectedGroupId}
            setDefaultList={setDefaultList}
            defaultList={defaultList}
          />
        </Main>
      </Box>
    </ContextProps.Provider>
  );
}
