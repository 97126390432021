import { baseService } from "./services";

export const getUserDetailById = (id: any) => baseService.get(`users/detail/${id}`);

export const getConversations = (payload: any) => baseService.get(`conversation/${payload.id}/${payload.isBlock || false}`);

export const getContacts = (payload: any) => baseService.get(`contacts/${payload.id}`);
export const getAllContacts = (payload: any) => baseService.get(`contacts/getAllContacts/${payload.id}`);

export const blockContacts = (payload: any) => baseService.post(`contacts/blockContacts`, payload);
export const unBlockContact = (payload: any) => baseService.post(`contacts/unBlockContact`, payload);

export const createGroup = (payload: any) => baseService.post(`groups/create`, payload);
export const updateGroup = (payload: any) => baseService.post(`groups/update`, payload);
export const getGroupDetails = (id: any) => baseService.get(`groups/detail/${id}`);
export const deleteGroupById = (payload: any) => baseService.post(`groups/delete`, payload);

export const getGroupMessages = (id: any, pageNumber?: number) => baseService.get(`groups/messages/${id}/${pageNumber || 1}`);
