import { Avatar, Badge, BadgeProps, Stack, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';

import SplitButton from "../dropDownAction"
import { conversationProps } from "../conversation";
import { blockContacts, deleteGroupById, unBlockContact } from "../../services/api";
import { useContext } from "react";
import { ContextProps } from "../../pages/homePage";
import { capitalizeFirstLetter, getDateDifference } from "../../utils/utility";

interface StyledBadgeProps extends BadgeProps {
  badgeColor?: string;
}

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'badgeColor' // Ensure badgeColor is not forwarded to the underlying DOM element
})<StyledBadgeProps>(({ theme, badgeColor }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: badgeColor || '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

interface listProps {
  list: conversationProps;
  setDefaultList: any;
  isBlocked?: boolean;
  setEditGroupId?: (val: number) => void;
  setSelectedTab?: (val: string) => void;
  socket?: any;
}

export interface optionsProps {
  action: string;
  onClick: () => void;
}


export const ConversationList = ({ list, setDefaultList, isBlocked = false, setEditGroupId, setSelectedTab, socket }: listProps) => {
  const { setSelectedGroupId, selectedGroupId } = useContext(ContextProps);

  const groupOptions: optionsProps[] = [{
    action: "Exit group",
    onClick: () => { console.log('Exit') }
  }, {
    action: "Edit group",
    onClick: () => { console.log('Edit') }
  }];

  const handleBlockedUser = async (blockedTo: any, groupId: any) => {
    const userId = localStorage.getItem('userId');
    await blockContacts({ blockedBy: userId, blockedTo, groupId });
    setDefaultList((val: any[]) => {
      return val.filter((val: { userId: any; }) => val.userId !== blockedTo);
    });
  };

  const handleUnBlockedUser = async (blockedTo: any, groupId: any) => {
    const userId = localStorage.getItem('userId');
    await unBlockContact({ blockedBy: userId, blockedTo, groupId });
    setDefaultList((val: any[]) => {
      return val.filter((val: { userId: any; }) => val.userId !== blockedTo);
    })
  };

  const deleteGroup = async (groupId: any) => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      await deleteGroupById({ userId: parseInt(userId), groupId });
      setDefaultList((val: any[]) => {
        return val.filter((val: { groupId: any; }) => val.groupId !== groupId);
      })
    }
  }

  const handleOption = (
    isGroup: boolean,
    isAdmin: boolean,
    isBlocked: boolean,
    userId: number | undefined,
    groupId: number | undefined
  ) => {
    if (isGroup && isAdmin) {
      return [{
        action: "Exit group",
        onClick: (e: any) => {
          e.stopPropagation();
          console.log('Exit');
        }
      }, {
        action: "Edit group",
        onClick: (e: any) => {
          e.stopPropagation();
          if (groupId && setEditGroupId && setSelectedTab) {
            setEditGroupId(groupId);
            setSelectedTab("2");
          }
        }
      }, {
        action: "Delete group",
        onClick: (e: any) => {
          e.stopPropagation();
          if (groupId && setEditGroupId && setSelectedTab) {
            deleteGroup(groupId);
          }
        }
      }];
    } else if (isGroup && !isAdmin) {
      return [{
        action: "Exit group",
        onClick: (e: any) => {
          e.stopPropagation();
          console.log('Exit')
        }
      }];
    } else if (isBlocked) {
      return [{
        action: "Unblock user",
        onClick: (e: any) => {
          e.stopPropagation();
          handleUnBlockedUser(userId, groupId)
        },
      }]
    } else {
      return [{
        action: "Block user",
        onClick: (e: any) => {
          e.stopPropagation();
          handleBlockedUser(userId, groupId)
        },
      }]
    }
  }

  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom={"1px solid lightgrey"}
      onClick={() => {
        if (setSelectedGroupId && list?.groupId)
          setSelectedGroupId(list?.groupId ?? null);
        else {
          const userId = localStorage.getItem('userId');
          socket.emit("create_room", { selectedUser: list.userId, loginUser: userId });
        }
      }}
      style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: selectedGroupId === list.groupId ? 'lightGrey' : 'white', borderRadius: selectedGroupId === list.groupId ? 10 : 0 }}
    >
      <Stack flexDirection={"row"} p="5px 0" alignItems={"center"}>
        {!list.isGroup ? <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          badgeColor={getDateDifference(list.lastSeen || null)}
        >
          <Avatar alt={list?.name} src={list.photo ?? " "} style={{ width: "30px", height: "30px" }} />
        </StyledBadge> : <Avatar alt={list?.name} src={list.photo ?? " "} style={{ width: "30px", height: "30px" }} />}

        <Typography style={{ padding: "0 10px" }}>{capitalizeFirstLetter(list?.name || "")}</Typography>

      </Stack>
      <Stack flexDirection={"row"}>
        {list?.unreadMessage !== 0 && <Typography style={{ padding: "0 10px", backgroundColor: "red", color: "white" }}>{list?.unreadMessage}</Typography>}

        <SplitButton
          options={handleOption(list.isGroup, list.isAdmin, isBlocked, list.userId, list.groupId)}
        />
      </Stack>
    </Stack>
  )
}