import React from 'react';
import Avatar from '@mui/material/Avatar';
import { SxProps } from "@mui/material";

interface AvatarProps {
  src?: string | null;
  name: string | null;
  avatarStyle?: SxProps;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0;i < string.length;i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0;i < 3;i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string | null) {
  if (name) {
    const nameVal = name.toUpperCase().split(" ");
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${nameVal[0][0]}${nameVal.length > 1 ? (nameVal[1][0] || "") : ""}`,
    };
  } else {
    return {}
  }
}

const ConditionalAvatar = ({ src, name, avatarStyle }: AvatarProps) => {
  return (
    <Avatar
      src={src || undefined}
      {...stringAvatar(name)}
      sx={avatarStyle || {}}
    />

  );
};

export default ConditionalAvatar;