import { Avatar, Box, Stack, styled, Typography } from "@mui/material";
import { formatDate } from "../../utils/utility";
import SplitButton from "../dropDownAction";
import { blockContacts } from "../../services/api";

const LeftMessageStyle = styled(Box)({
  position: "relative",
  marginLeft: "20px",
  marginBottom: "10px",
  padding: "10px",
  backgroundColor: "#A8DDFD",
  maxWidth: "60%",
  minWidth: "100px",
  textAlign: "left",
  font: "400 .9em 'Open Sans', sans-serif",
  border: "1px solid #97C6E3",
  borderRadius: "10px",
});

const RightMessageStyle = styled(Box)({
  position: "relative",
  marginRight: "20px",
  marginLeft: "20px",
  marginBottom: "10px",
  padding: "10px",
  backgroundColor: "orange",
  minWidth: "100px",
  maxWidth: "60%",
  // textAlign: "right",
  font: "400 .9em 'Open Sans', sans-serif",
  border: "1px solid orangered",
  borderRadius: "10px",
});

export const MessageLeft = (props: any) => {
  const message = props.message ? props.message : "no message";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "";
  const timestamp = formatDate(props.timestamp);

  const handleBlockedUser = async (blockedTo: any, groupId: any) => {
    const userId = localStorage.getItem('userId');
    await blockContacts({ blockedBy: userId, blockedTo, groupId });
    if (props.setDefaultList)
      props.setDefaultList((val: any[]) => {
        return val.filter((val: { userId: any; }) => val.userId !== blockedTo);
      })
  };

  const handleOptions = [{
    action: "Report Message",
    onClick: (e: any) => {
      e.stopPropagation();
    }
  }, {
    action: "Block User",
    onClick: (e: any) => {
      e.stopPropagation();
      handleBlockedUser(props.messageBy, props.groupId)
    }
  }
  ]
  return (
    <Stack direction="row">
      <Avatar alt={displayName} src={photoURL}></Avatar>
      <LeftMessageStyle>
        <Stack flexDirection={"row"} alignItems={"center"} textAlign={"left"}>
          <Typography component={"span"} marginRight={1} fontWeight={"bold"}>{props.displayName}</Typography>
          <Typography component={"span"} fontSize={12}>{timestamp}</Typography>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} textAlign={"left"}>
          {props?.image && (
            <img
              src={props?.image}
              alt="uploaded"
              style={{ height: '200px', width: '200px', borderRadius: '50%' }}
            />
          )}
        </Stack>
        <Typography component={"p"} textAlign={"left"}>{message}</Typography>

      </LeftMessageStyle>
      <SplitButton
        options={handleOptions}
        styleOption={{ alignSelf: "end", marginBottom: 12, height: 24 }}
      />
    </Stack>
  );
};

export const MessageRight = (props: any) => {
  const message = props.message ? props.message : "no message";
  const timestamp = formatDate(props.timestamp);
  const photoURL = props.photoURL ? props.photoURL : " ";

  return (
    <Stack direction="row-reverse">
      <RightMessageStyle>
        <Stack flexDirection={"row"} alignItems={"center"} textAlign={"left"}>
          <Typography component={"span"} marginRight={1} fontWeight={"bold"}>{props.displayName}</Typography>
          <Typography component={"span"} fontSize={12}>{timestamp}</Typography>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} textAlign={"left"}>

          {props?.image && (
            <img
              src={props?.image}
              alt="uploaded"
              style={{ height: '200px', width: '200px' }}
            />
          )}
        </Stack>
        <Typography component={"span"} textAlign={"right"}>{message}</Typography>
      </RightMessageStyle>
      <Avatar alt={props.displayName || ""} src={photoURL}></Avatar>
    </Stack>
  );
};
