import { useEffect } from "react";
import './App.css';
import AppRoutes from './route';

function App() {
  useEffect(() => {
    // localStorage.setItem('userId', '1');
  }, []);

  return (
    <div>
      <AppRoutes/>
    </div>
  );
}

export default App;
