import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useEffect, useState } from "react";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Picker from "emoji-picker-react";
import * as io from "socket.io-client";
import { containsEmoji, fileValidation } from "../../utils/utility";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { API_LINK } from "../../contants/app";

const socketM = io.connect(API_LINK);

export const TextInput = ({ isChatDisabled, selectedGroupId, loginUserId, isMessageAvailable, socket }: { socket: any; isChatDisabled: boolean; selectedGroupId: number | null; loginUserId: number; isMessageAvailable: boolean }) => {
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState<string | null>(null);
  const [image, setImage] = useState<any>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [errorFile, setErrorFile] = useState<string | null>(null);

  const onEmojiClick = (event: any) => {
    setInputStr((prevInput) => prevInput + event.emoji);
    setShowPicker(false);
  };

  const getMessageType = (message: string, imageFile: File | null) => {
    if (containsEmoji(message)) {
      if (imageFile) {
        return "isEmoziImage"
      } else {
        return "isEmoji"
      }
    } else {
      if (imageFile) {
        return "image"
      } else {
        return "text";
      }
    }
  }

  const sendMessage = async (currentMessage: string) => {
    if (imageFile) {
      const fileError = fileValidation(imageFile);
      if (fileError) {
        setErrorFile(fileError);
        setImageFile(null);
        setImage(null);
        return;
      }
    }
    if (currentMessage !== "") {

      const messageData = {
        room: selectedGroupId,
        message: {
          group_id: selectedGroupId,
          message: currentMessage,
          message_by: loginUserId,
          message_type: getMessageType(currentMessage, imageFile),
          file: image,
        }
      };
      setErrorFile('');
      setImageFile(null);
      setImage(null);
      socketM.emit("send_message", messageData);
      setTyping(false);
      socket.emit('stop_typing', { room: selectedGroupId });
      // setMessageList((list: any) => [...list, messageData]);
      setInputStr("");
    }
  };

  useEffect(() => {
    socket.on('typing', (data: any) => {

      setIsTyping(data?.userName);
    });

    socket.on('stop_typing', (data: any) => {
      setIsTyping(null);
    });


    return () => {
      socket.off('typing');
      socket.off('stop_typing');
    };
  }, [socket]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      sendMessage(inputStr);
      // You can add your logic here, like calling a function or updating state
    }
  };

  const handleInputChange = (e: any) => {
    setInputStr(e.target.value)

    if (!typing) {
      setTyping(true);
      let userData: any = localStorage.getItem('userData');
      let userName = ""
      if (userData) {
        userData = JSON.parse(userData || "");
        userName = userData?.profiles?.use_profile === 1 ? userData?.profiles?.profile_name : `${userData?.fname} ${userData.lname}`;
      }

      socket.emit('typing', { room: selectedGroupId, userName: userName }); // Replace 'User1' with the actual username
    }

    // Emit stop typing event after 2 seconds of inactivity
    setTimeout(() => {
      setTyping(false);
      socket.emit('stop_typing', { room: selectedGroupId });
    }, 2000);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files.length > 0) {
      const file = event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
        setImageFile(file);
      }
    }
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `1px auto`,
      position: "absolute",
      bottom: 0,
      flexDirection: "column",
      paddingTop: 20
    }}>
      {isTyping && <Typography fontSize={16}>{isTyping} is typing...</Typography>}
      {!isMessageAvailable && isChatDisabled && <Typography textAlign={"center"} color={"red"}>You cannot send messages to this person at this time</Typography>}
      <TextField
        id="standard-text"
        label="Enter a message"
        value={inputStr}
        onChange={(e) => {
          handleInputChange(e);
        }}
        style={{
          width: "100%"
        }}
        disabled={isChatDisabled}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {image && (
                <img
                  src={image}
                  alt="uploaded"
                  style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                />
              )}
            </InputAdornment>
          )
        }}
      />
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        m={1}
      >
        <Stack display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <EmojiEmotionsIcon
            color="primary"
            onClick={() => {
              if (!isChatDisabled)
                setShowPicker(true);
            }}
          />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label
            htmlFor="icon-button-file"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: "row-reverse",
              cursor: 'pointer',
            }}
          >
            <IconButton color="primary" aria-label="upload picture" component="span">
              <CameraAltOutlinedIcon
                color="info"
                fontSize="large"
              // style={{ marginRight: 8 }}
              />
            </IconButton>
          </label>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (inputStr && inputStr !== "") {
              sendMessage(inputStr);
            }
          }}
        >
          <SendIcon />
        </Button>
      </Stack>
      {showPicker && (
        <Picker
          onEmojiClick={onEmojiClick}
        />
      )}
    </div>
  )
}



