import { Box } from "@mui/material";
import AddEditGroupInfo from "./addEditGroupInfo";
import MessageBox from "./messageBox";
import { useEffect } from "react";
import { conversationProps } from "../conversation";
import * as io from "socket.io-client";
import { API_LINK } from "../../contants/app";

const socket = io.connect(API_LINK);

interface RightContentProps {
  selectedTab: string;
  showDrawer: boolean;
  setSelectedTab: any;
  setEditGroupId: (val: number | null) => void;
  editGroupId: number | null;
  selectedGroupId: number | null;
  setDefaultList: (val: conversationProps[]) => void;
  defaultList: conversationProps[];
  setSelectedGroupId: any;
}

export default function RightContent({
  selectedTab,
  showDrawer,
  setSelectedTab,
  setEditGroupId,
  editGroupId,
  selectedGroupId,
  setDefaultList,
  defaultList,
  setSelectedGroupId
}: RightContentProps) {


  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (selectedGroupId) {
      socket.emit("join_room", { room: selectedGroupId, userId: userId });
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (selectedTab === "2") {
      setSelectedGroupId(null);
    }
  }, [selectedTab])

  return (
    <Box padding={0} margin={0}>
      {selectedTab !== "2" && <MessageBox setSelectedTab={setSelectedTab} defaultList={defaultList} setEditGroupId={setEditGroupId} setDefaultList={setDefaultList} isChatDisabled={selectedTab === "4"} socket={socket} />}
      {selectedTab === "2" && (
        <AddEditGroupInfo
          showDrawer={showDrawer}
          setSelectedTab={setSelectedTab}
          editGroupId={editGroupId}
        />
      )}
    </Box>
  );
}
